import type { MutableRefObject } from 'react';

export interface BaseTooltipProps {
	rightRail?: boolean;
	setTooltipOpen: (tooltipOpen: boolean) => void;
	tooltipOpen: boolean;
}

type EitherRef<T> =
	| ((instance: T | null) => void)
	| MutableRefObject<T | null>
	| null;

export const isMutableRef = <T>(
	unknown: EitherRef<T>,
): unknown is MutableRefObject<T | null> => {
	return (
		!!unknown &&
		typeof (unknown as MutableRefObject<T | null>).current !== 'undefined'
	);
};
