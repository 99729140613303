import { Body1, Body2 } from '@xo-union/tk-ui-typography';
import React, { type FC } from 'react';
import TooltipInfo from '../containers/ResultsHeader/TooltipInfo';
import Styles from './SponsoredResultsDisclaimer.styles.scss';

interface SponsoredResultsDisclaimerProps {
	rightRail?: boolean;
}

export const SponsoredResultsDisclaimer: FC<
	SponsoredResultsDisclaimerProps
> = ({ rightRail }) => {
	if (rightRail) {
		return (
			<div className={Styles.rightRailWrapper}>
				<Body2 className={Styles.rightRailHeader}>Sponsored results</Body2>
				<TooltipInfo rightRail />
			</div>
		);
	}
	return (
		<div className={Styles.wrapper}>
			<Body1 bold>Sponsored results</Body1>
			<TooltipInfo />
		</div>
	);
};
