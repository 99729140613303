import type { IconName } from '@xo-union/dist-iconography';
import type { VendorBadge } from '../types';

export const HIGHLY_RATED_BADGE: VendorBadge = {
	icon: 'star',
	label: 'Highly Rated',
	type: 'attribute',
};

export const CLOSE_TO_YOU_BADGE: VendorBadge = {
	icon: 'address',
	label: 'Close to You',
	type: 'attribute',
};

export const BOW_BADGE: VendorBadge = {
	icon: 'trophy' as IconName, // Current types are not compatible with this new icon.
	label: 'Best of Weddings',
	type: 'attribute',
};

export const PRICING_AVAILABLE_BADGE: VendorBadge = {
	icon: 'budgeter',
	label: 'Pricing Available',
	type: 'attribute',
};

export const VIRTUAL_TOUR_BADGE: VendorBadge = {
	icon: 'virtual_tour' as IconName, // Current types are not compatible with this new icon.
	label: 'Virtual Tours',
	type: 'attribute',
};

export const NEW_BADGE: VendorBadge = {
	label: 'New!',
	type: 'call_out',
};
