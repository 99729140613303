import React, { forwardRef, useEffect } from 'react';
import Tooltip from '../components/Tooltip';
import { BaseTooltipProps, isMutableRef } from '../types';

const Desktop = forwardRef<HTMLDivElement, BaseTooltipProps>((props, ref) => {
	const { rightRail, setTooltipOpen, tooltipOpen } = props;

	const openTooltip = () => {
		setTooltipOpen(true);
	};

	const closeTooltip = () => {
		setTooltipOpen(false);
	};

	useEffect(() => {
		if (isMutableRef(ref) && ref.current) {
			ref.current.addEventListener('mouseenter', openTooltip);
			ref.current.addEventListener('mouseleave', closeTooltip);
		}

		return () => {
			if (isMutableRef(ref) && ref.current) {
				ref.current.removeEventListener('mouseenter', openTooltip);
				ref.current.removeEventListener('mouseleave', closeTooltip);
			}
		};
	}, [ref]);

	return (
		<Tooltip
			ref={ref}
			isDesktop
			rightRail={rightRail}
			setTooltipOpen={setTooltipOpen}
			tooltipOpen={tooltipOpen}
		/>
	);
});

export default Desktop;
