import { useListingsSections } from '@hooks/use-listings-sections';
import Icon from '@xo-union/tk-component-icons';
import {
	TooltipContainer,
	Tooltip as UnionTooltip,
} from '@xo-union/tk-component-tooltips';
import React, { forwardRef, useMemo } from 'react';
import Constants from '../../../../constants';
import Styles from '../../styles.scss';
import type { BaseTooltipProps } from '../types';

interface TooltipProps extends BaseTooltipProps {
	isDesktop: boolean;
}

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
	const { isDesktop, rightRail, setTooltipOpen, tooltipOpen } = props;
	const toggleOpenTooltip = () => setTooltipOpen(!tooltipOpen);
	const isListingsSections = useListingsSections();

	const arrowPosition = useMemo(() => {
		if (isDesktop && rightRail) return 'top';
		return isDesktop ? 'left' : 'top';
	}, [isDesktop, rightRail]);

	const arrowOffset = useMemo(() => {
		if (isDesktop && rightRail) return 50;
		if (!isDesktop && isListingsSections) return 60;
		return isDesktop ? 50 : 95;
	}, [isDesktop, isListingsSections, rightRail]);
	const tooltipTarget = isListingsSections
		? Styles.listingsSectionsTooltipTarget
		: Styles.tooltipTarget;
	const tip = useMemo(() => {
		if (rightRail || (isListingsSections && !isDesktop))
			return Styles.rightRailTip;
		return Styles.tip;
	}, [rightRail, isDesktop, isListingsSections]);

	return (
		<TooltipContainer>
			<div
				className={tooltipTarget}
				onClick={toggleOpenTooltip}
				onKeyPress={toggleOpenTooltip}
				ref={ref}
				role="button"
				tabIndex={0}
			>
				<Icon name="info" size="sm" />
			</div>
			{tooltipOpen && (
				<UnionTooltip
					arrowPosition={arrowPosition}
					arrowOffset={arrowOffset}
					className={tip}
					role="alert"
				>
					{Constants.TOOLTIP_TEXT}
				</UnionTooltip>
			)}
		</TooltipContainer>
	);
});

export default Tooltip;
