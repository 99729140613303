import { selectAssignment } from '@redux/experiments/selectors/index';
import type { ListingsSectionsAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const listingsSectionsSelector = (state: State) => {
	return selectAssignment(state, [
		'listingsSections',
	]) as ListingsSectionsAssignment;
};

export { listingsSectionsSelector };
