import { listingsSectionsSelector } from '@redux/experiments/selectors/listings-sections';
import { useAppSelector } from '@redux/hooks';

export const useListingsSections = () => {
	const listingsSectionsAssignment = useAppSelector((state) =>
		listingsSectionsSelector(state),
	);
	const isValidAssignment = listingsSectionsAssignment === 'listings-sections';
	const sort = useAppSelector((state) => state.search.sort);
	const isValidSort = sort === 'featured' || sort === 'recommended';

	return Boolean(isValidAssignment && isValidSort);
};
